<template>
  <v-card class="RootCardStyle" color="#1e1e1e" tile>
    <v-card-text class="yellowColorVariant--text maxWidthVariant">
      <div class="fontsize25px font-weight-bold">Terms and Conditions</div>
      <div class="fontsize18px white--text font-weight-bold pt-5">
        Introduction:
      </div>
      <div class="fontsize14px text-justify">
        Welcome to QRJungle! By using our app and services, you agree to comply
        with and be bound by the following Terms and Conditions. Please read
        them carefully. If you do not agree to these terms, you may not use our
        app.
      </div>
      <div class="fontsize18px white--text font-weight-bold pt-5">
        Account Registration
      </div>

      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel v-for="(item, i) in AccountRegistration" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">
        Purchase of Custom QR Codes:
      </div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel v-for="(item, i) in PurchaseQRCodes" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">
        Use of QR Codes:
      </div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel v-for="(item, i) in UsesOfQRCodes" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">
        Intellectual Property
      </div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel v-for="(item, i) in IntelluctualProperty" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">
        Limitation of Liability:
      </div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel v-for="(item, i) in Limitations" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">User Conduct:</div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel v-for="(item, i) in UserConduct" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">Termination:</div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel v-for="(item, i) in Termination" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">
        Changes to Terms and Conditions:
      </div>
      <div class="fontsize14px text-justify">
        QRJungle reserves the right to update these Terms and Conditions at any
        time. We will notify you of any changes by posting the new Terms and
        Conditions on our app. You are advised to review these Terms and
        Conditions periodically for any changes.
      </div>
      <div class="fontsize18px white--text font-weight-bold pt-3">
        Governing Law:
      </div>
      <div class="fontsize14px text-justify">
        These Terms and Conditions are governed by and construed in accordance
        with the laws of [Your Country/State], without regard to its conflict of
        law principles.
      </div>
      <div class="fontsize18px white--text font-weight-bold pt-3">
        Contact Us
      </div>
      <div class="fontsize14px text-justify">
        If you have any questions about this Privacy Policy or our data
        practices, please contact us at: please contact us at
        mobil80solutions.services@gmail.com.
      </div>
      <div class="pt-5 fontsize14px">
        By using QRJungle, you agree to the terms of these Terms and Conditions.
      </div>
      <!--  <div class="fontsize18px white--text font-weight-bold">
          Data Security:
        </div>
        <div class="fontsize14px text-justify">
          We implement a variety of security measures to maintain the safety of
          your personal information. All sensitive information, such as
          authentication data, is transmitted via secure technologies. We also
          ensure that our third-party service providers, such as Razorpay, adhere
          to strict security and privacy standards.
        </div>
        <div class="fontsize18px white--text font-weight-bold pt-3">
          Third-Party Services:
        </div>
        <div class="fontsize14px text-justify">
          QRJungle uses third-party services, including Razorpay for payment
          processing. These services are governed by their own privacy policies.
          We encourage you to review the privacy policies of these third-party
          services to understand their practices.
        </div>
        <div class="fontsize18px white--text font-weight-bold pt-3">
          You have the right to:
        </div>
        <v-expansion-panels class="mb-6 expansionalStyle">
          <v-expansion-panel v-for="(item, i) in YoursRightsArray" :key="i">
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div class="font-weight-bold">{{ item.Title }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-justify">{{
              item.description
            }}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="fontsize18px white--text font-weight-bold">
          Changes to This Privacy Policy:
        </div>
        <div class="fontsize14px text-justify">
          QRJungle may update this Privacy Policy from time to time. You are
          advised to review this Privacy Policy periodically for any changes.
        </div>
        <div class="fontsize18px white--text font-weight-bold pt-3">
          Contact Us
        </div>
        <div class="fontsize14px text-justify">
          If you have any questions about this Privacy Policy or our data
          practices, please contact us at: please contact us at
          mobil80solutions.services@gmail.com.
        </div>
        <div class="pt-5 fontsize14px">
          By using QRJungle, you agree to the terms of this Privacy Policy.
        </div> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    AccountRegistration: [
      {
        Title: "Email Authentication:",
        description:
          "To use QRJungle, you must create an account using your email address. We use an OTP (One-Time Password) system for authentication.",
      },
      {
        Title: "Account Responsibility:",
        description:
          "You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
      },
      {
        Title: "Usage Data",
        description:
          "Device Information: We collect information about the device you use to access our app, including IP address, device type, operating system, and app usage data. This helps us improve our services and provide a better user experience.",
      },
    ],
    PurchaseQRCodes: [
      {
        Title: "Linking to Email",
        description:
          "All purchased QR codes are linked to the email address used for account registration. Ensure that your email address is valid and accessible.",
      },
      {
        Title: "Payment Processing",
        description:
          "Payments are processed through the Razorpay API. By making a purchase, you agree to comply with Razorpay's terms and conditions. QRJungle does not store or manage payment information directly.",
      },
    ],
    UsesOfQRCodes: [
      {
        Title: "Personal and Commercial Use",
        description:
          "QRJungle provides custom QR codes for both personal and commercial use. You are responsible for ensuring that your use of QR codes complies with applicable laws and regulations.",
      },
      {
        Title: "Prohibited Uses",
        description:
          "You may not use QR codes for any illegal or unauthorized purpose. This includes, but is not limited to, using QR codes to link to harmful or malicious content.",
      },
    ],
    IntelluctualProperty: [
      {
        Title: "Ownership",
        description:
          "QRJungle provides custom QR codes for both personal and commercial use. You are responsible for ensuring that your use of QR codes complies with applicable laws and regulations.",
      },
      {
        Title: "License",
        description:
          "You may not use QR codes for any illegal or unauthorized purpose. This includes, but is not limited to, using QR codes to link to harmful or malicious content.",
      },
    ],
    Limitations: [
      {
        Title: "No Warranties",
        description: `QRJungle provides the app and services "as is" and "as available," without any warranties of any kind, either express or implied.`,
      },
      {
        Title: "Liability Limit",
        description:
          "To the maximum extent permitted by law, QRJungle will not be liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, arising out of or in connection with your use of the app and services.",
      },
    ],
    UserConduct: [
      {
        Title: "Compliance",
        description: ` You agree to use QRJungle in compliance with all applicable laws and regulations.`,
      },
      {
        Title: "Prohibited Activities",
        description:
          "You may not engage in any activity that interferes with or disrupts the app or services, including but not limited to hacking, phishing, or spamming.",
      },
    ],
    Termination: [
      {
        Title: "Termination by You",
        description: `You may terminate your account at any time by contacting us at support@qrjungle.com.`,
      },
      {
        Title: "Termination by Us",
        description:
          "QRJungle reserves the right to terminate or suspend your account without prior notice if you violate these Terms and Conditions or engage in any prohibited activities.",
      },
    ],
  }),
};
</script>

<style>
.RootCardStyle {
  background-size: cover !important;
}
.maxWidthVariant {
  padding: 50px !important;
  /* padding-right: 80px !important; */
  /* max-width: 80% !important; */
}
.expansionalStyle.theme--light.v-expansion-panels .v-expansion-panel {
  margin: 2px !important;
  border: 2px solid #fdcb0a !important;
}
</style>
